/**
 * @title Masthead
 * @id modules/masthead
 * @markup masthead/masthead.html
 * @width 24
 */
:root {
  --masthead-logo-height: calc(var(--vertical-stride) * 3.5);
  --masthead-title-height: calc(var(--vertical-stride) * 1.65);
}

@custom-media --masthead-wide (--breakpoint-medium-up);
@custom-media --masthead-narrow (--breakpoint-narrow);

.masthead__content {
  background-color: var(--color-pastel-green);
}

.masthead__top {
  padding-top: var(--vertical-stride);
}

.masthead__title {
  float: left;
  padding-left: calc(100% / 36);

  /*
   * Position the title roughly centered with the logo but slightly further up
   */
  padding-top: calc((var(--masthead-logo-height) - var(--masthead-title-height)) / 2.4);
  text-decoration: none;
  width: calc(100% / 24 * 14);
}

.masthead__title > svg {
  height: var(--masthead-title-height);
  width: auto;
}

.masthead__logo {
  float: left;
  text-align: center;
  width: calc(100% / 24 * 4);
}

.masthead__logo > svg {
  height: var(--masthead-logo-height);
  width: auto;
}

.masthead__menu-button {
  align-items: center;
  background-color: transparent;
  border: 0;
  display: flex;
  flex-direction: column;
  line-height: 1;
  margin: 0;
  padding: 0 calc(var(--horizontal-stride) / 2);
}

.masthead__menu {
  clear: left;
}

.masthead__menu__content {
  clear: left;
  display: block;
}

.masthead__search {
  float: left;
  font-family: "Mallory MP", sans-serif;
  font-size: 10px;
  padding: var(--vertical-stride) calc(100% / 36) 0;
  width: calc(100% / 24 * 6);
}

.masthead__main {
  background-color: var(--color-light-green);
  padding: var(--vertical-stride) 0;
  width: calc(100% / 24 * 24);
}

/*
 * On larger screens, when the masthead is fixed, give the fixed content the
 * same content box width as .l-content
*/
@media (--masthead-wide) {
  .masthead.is-fixed .masthead__content {
    box-sizing: content-box;
    left: 0;
    margin: 0 auto;
    max-width: 1280px;
    position: fixed;
    right: 0;
    top: 0;
    width: 96%;
    z-index: 1;
  }

  .masthead__menu-button {
    display: none;
  }

  .masthead__menu {
    /* Allow search field to float up */
    display: inline;
  }
}

@media (--breakpoint-medium) {
  .masthead__title {
    padding-left: 3%;
  }

  .masthead__logo {
    padding-top: 20px;
  }

  .masthead__logo > svg {
    height: 50px;
  }
}

/* On smaller screens ... */
@media (--masthead-narrow) {
  .masthead {
    /* Override inline style from sticky-menu script */
    height: auto !important; /* stylelint-disable-line */
  }

  .masthead__top {
    display: flex;
    padding-top: calc(var(--vertical-stride) / 2);
  }

  .masthead__title,
  .masthead__logo {
    float: none;
    height: 36px;
    padding-top: 4px;
    width: auto;
  }

  .masthead__title {
    flex: 1;
    padding-top: 10px;
  }

  .masthead__logo {
    text-align: left;
  }

  .masthead__title > svg {
    height: 15px;
  }

  .masthead__logo > svg {
    height: 35px;
  }

  .masthead__menu-button {
    font-size: 12px;
    height: 40px;
    padding: 0 calc(var(--horizontal-stride) / 2);
  }

  .masthead__menu {
    background-color: color(var(--color-pastel-green) tint(40%));
    display: none;
    height: 0;
    overflow: hidden;
  }

  .masthead__menu.is-animating {
    transition: height 250ms ease-in;
  }

  .masthead__menu.is-open {
    display: block;
    height: auto;
  }

  .masthead__search {
    float: none;
    padding: calc(var(--vertical-stride) * 0.4) calc(100% / 24);
    width: auto;
  }

  .masthead__menu__content {
    flex-direction: column;
  }

  .masthead__main {
    background-color: transparent;
    padding: 10px 0 0 0;
    width: auto;
  }
}

@media print {
  .masthead {
    display: none;
  }
}
