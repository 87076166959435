/*
 * Micro clearfix, from http://nicolasgallagher.com/micro-clearfix-hack/
 *
 * We use display: block; instead of display: table; because we do not want to
 * contain the top-margins of child elements.
 */
%clearfix::before,
%clearfix::after {
  content: " ";
  display: block;
}

%clearfix::after {
  clear: both;
}
