/*
 * Used for narrow content within body main.
 */
@media (--main-width-18) {
  .l-narrow-content {
    margin-left: calc(100% / 18 * 2);
    width: calc(100% / 18 * 14);
  }

  .l-narrow-content--extra-narrow {
    padding-left: calc(var(--horizontal-stride) * 2);
    padding-right: calc(var(--horizontal-stride) * 2);
  }

  .l-narrow-content--right {
    padding-left: calc(100% / 18 * 1);
  }
}

@media (--main-width-16) {
  .l-narrow-content {
    margin: 0 auto;
    width: calc(100% / 16 * 14);
  }

  .l-narrow-content--extra-narrow {
    padding-left: calc(var(--horizontal-stride) * 2);
    padding-right: calc(var(--horizontal-stride) * 2);
  }

  .l-narrow-content--right {
    padding-left: calc(100% / 16 * 1);
  }
}

@media print {
  .l-narrow-content {
    width: 100%;
  }
}
