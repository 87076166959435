/**
 * @title Article illustration
 * @id modules/article-illustration
 * @markup article-illustration/article-illustration.html
 * @width 18
 */
.article-illustration {
  margin-bottom: var(--vertical-stride);
}

.article-illustration__content {
  position: relative;
}

.article-illustration__image {
  position: relative;
}

.article-illustration__attribution {
  bottom: 0;
  position: absolute;
  right: 0;
}

.article-illustration__caption {
  font-style: italic;
  line-height: calc(var(--vertical-stride) * 1.1);
}

/* Aspect ratio */
.article-illustration__image::before {
  content: "";
  display: block;
  padding-top: 70%;
}

.article-illustration__image > img {
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

@media (--cell-width-6-of-18) {
  .article-illustration {
    padding-left: calc(100% / 18 * 2);
    width: calc(100% / 18 * 13);
  }
}

@media (--cell-width-8-of-16) {
  .article-illustration {
    padding-left: calc(100% / 18 * 2);
    width: calc(100% / 16 * 13);
  }
}

/**
 * @title Wide illustration
 * @id modules/article-illustration/wide
 * @markup article-illustration/wide.html
 * @width 18
 */
.article-illustration--wide .article-illustration__image::before {
  padding-top: 45%;
}

@media (--cell-width-6-of-18) {
  .article-illustration--wide {
    padding-left: 0;
    width: 100%;
  }
}

@media (--cell-width-8-of-16) {
  .article-illustration--wide {
    padding-left: 0;
    width: 100%;
  }
}

@media print {
  .article-illustration__attribution {
    position: static;
  }

  .article-illustration__image {
    /* We need to use !important in order to override the inline style */
    background-image: none !important;  /* stylelint-disable-line */
  }

  .article-illustration__image::before {
    display: none;
  }

  .article-illustration__image > img {
    display: block;
    height: auto;
    margin: 0 auto;
    max-height: 400px;
    max-width: 100%;
    opacity: 1;
    position: static;
    width: auto;
  }
}
