/**
 * @title Question
 * @id modules/question
 * @markup question/question.html
 * @width 10
 */
.question {
  font-weight: bold;
}

.question__name {
  float: left;
  margin: 0;
  padding-right: 0.75ex;
  text-transform: uppercase;
}

.question--turquoise .question__name { color: var(--color-turquoise); }
.question--blue .question__name { color: var(--color-blue); }
.question--green .question__name { color: var(--color-green); }
.question--orange .question__name { color: var(--color-orange); }
