/**
 * @title Comment Form
 * @id modules/comment-form
 * @markup comment-form/default.html
 * @width 12
 */
.comment-form {
  margin-bottom: 40px;
}

.comment-form__error {
  color: red;
}

.comment-form__field {
  margin-bottom: 20px;
}

.comment-form__label {
  display: block;
  font-weight: bold;
}

.comment-form__input {
  border: 1px solid #666666;
  padding: 5px 10px;
  resize: vertical;
  width: 100%;
}

.comment-form__recaptcha {
  margin-bottom: 20px;
}

.comment-form__submit {
  background-color: green;
  border: 0;
  color: white;
  margin: 0;
  padding: 5px 10px;
}

/**
 * @title With an error
 * @id modules/comment-form/error
 * @markup comment-form/error.html
 * @width 12
 */
.comment-form__input--error {
  border-color: darkred;
  box-shadow: inset 0 0 4px 0 red;
}

@media print {
  .comment-form {
    display: none;
  }
}
