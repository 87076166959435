/**
 * Used for all article body text blocks.
 *
 * @title Article text block
 * @id modules/article-text-block
 * @markup article-text-block/article-text-block.html
 * @width 12
 */
.article-text-block {
  margin-bottom: calc(var(--vertical-stride) * 2);
}

.article-text-block > p {
  margin: 0;
  text-indent: var(--horizontal-stride);
}

.article-text-block > p:first-child {
  text-indent: 0;
}

.article-text-block > ol,
.article-text-block > ul {
  margin: var(--vertical-stride) 0;
}

/*
 * Prevent first and last child from having margins that hit the top and
 * bottom of the parent. */
.article-text-block > *:first-child { margin-top: 0; }
.article-text-block > *:last-child { margin-bottom: 0; }

/* Color links within the article text */
.article-text-block--turquoise a { color: var(--color-turquoise); }
.article-text-block--green a { color: var(--color-green); }
.article-text-block--blue a { color: var(--color-blue); }
.article-text-block--orange a { color: var(--color-orange); }

@media print {
  .article-text-block {
    margin-left: 0;
    margin-right: 0;
  }
}
