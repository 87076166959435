/**
 * @title Byline
 * @id atoms/byline
 * @markup byline/byline.html
 * @width 4
 */
.byline {
  font-family: "Mallory MP", sans-serif;
  font-feature-settings: "c2sc", "onum";
  font-size: 13px;
  line-height: calc(var(--vertical-stride) * 1.15);
  text-transform: uppercase;
}

.byline__date,
.byline__author {
  display: inline;
  margin: 0;
}

.byline__date::after {
  content: " \2022";
  display: inline;
}

.byline--turquoise { color: var(--color-turquoise); }
.byline--blue { color: var(--color-blue); }
.byline--green { color: var(--color-green); }
.byline--orange { color: var(--color-orange); }
