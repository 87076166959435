/**
 * @title Article card
 * @id modules/article-card
 * @markup article-card/article-card.html
 * @width 6
 */
@custom-media --wide-article-card-width-12-of-18 (--wide-cell-width-12-of-18);
@custom-media --wide-article-card-width-16-of-16 (--wide-cell-width-16-of-16);
@custom-media --wide-article-card-width-24-of-24 (--wide-cell-width-24-of-24);

.article-card {
  background-color: var(--color-light-green);
  color: inherit;
  display: flex;
  flex-direction: column;
  position: relative;
  text-decoration: none;
  width: 100%;
  z-index: 0;
}

/* IE10-11 workaround
 * See: https://github.com/philipwalton/flexbugs#3-min-height-on-a-flex-container-wont-apply-to-its-flex-items
 */
.article-card__flex-workaround {
  display: flex;
  flex-direction: column;
  min-height: var(--card-height-medium-min);
  width: 100%;
}

.article-card__content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-end;
  padding: 20px;
  width: 100%;
}

.article-card__image {
  position: relative;
}

/* https://justmarkup.com/log/2015/07/dealing-with-long-words-in-css/ */
.article-card__heading {
  @extend %headline-two;

  hyphens: auto;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

/* Aspect ratio */
.article-card__image::before {
  content: "";
  display: block;
  padding-top: 65%;
}

/*
 * This image is visibly hidden, but makes it possible to right click and copy
 * the image which the background-image on the parent does not support.
 */
.article-card__image > img {
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.article-card__category {
  font-family: "Mallory MP", sans-serif;
  font-feature-settings: "c2sc";
  font-size: 13px;
  line-height: calc(var(--vertical-stride) * 1.1);
  margin: 0;
  order: -1;  /* Show above the heading */
  padding-right: 30px; /* Make room for comment count */
  text-transform: uppercase;
}

.article-card__body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-end;
}

.article-card__lead-text {
  margin: 0;
}

.article-card__lead-text--long {
  display: none;
}

/*
 * Create a fake "Read more" link after the lead paragraph. The entire card
 * itself is a link so this is only a visual hint that the card can be clicked.
 */
.article-card__lead-text::after {
  content: "Les mer";
  padding-left: 0.5ex;
  text-decoration: underline;
  white-space: nowrap;
}

.article-card__comments {
  opacity: 0.5;
  position: absolute;
  right: 10px;
  top: 5px;
}

.article-card--turquoise .article-card__category,
.article-card--turquoise .article-card__lead-text::after {
  color: var(--color-turquoise);
}

.article-card--green .article-card__category,
.article-card--green .article-card__lead-text::after {
  color: var(--color-green);
}

.article-card--blue .article-card__category,
.article-card--blue .article-card__lead-text::after {
  color: var(--color-blue);
}

.article-card--orange .article-card__category,
.article-card--orange .article-card__lead-text::after {
  color: var(--color-orange);
}

/**
 * TODO(scb): Add lead to the markup but hide it (and display it in the wide)
 * version).
 * @title With an image
 * @id modules/article-card/image
 * @markup article-card/image.html
 * @width 6
 */
.article-card--image .article-card__content {
  justify-content: flex-end;
}

.article-card--image .article-card__body {
  display: flex;
  flex: 0;
}

.article-card--image .article-card__lead {
  display: none;
}

/*
  TODO(scb): Stardoc currently doesn't understand stardoc comments witin nested
  blocks. So for the time being I just duplicate the stardoc comments from the
  media query on the outside so that stardoc can see them.
*/

/**
 * @title Wide version
 * @id modules/article-card/wide
 * @markup article-card/wide.html
 * @width 12
 */

/**
 * @title Wide version with an image
 * @id modules/article-card/wide-with-image
 * @markup article-card/wide-with-image.html
 * @width 12
 */

@media (--wide-article-card-width-12-of-18) {
  /**
   * @title Wide version
   * @id modules/article-card/wide
   * @markup article-card/wide.html
   * @width 12
   */
  .article-card--wide,
  .article-card--wide .article-card__flex-workaround {
    flex-direction: row;
  }

  .article-card--wide .article-card__heading {
    /*
      @extend %headline-one;

      We can't use extend within a media, so we need to hardcode the same values
      as the placeholder has, sadly.
    */
    font-size: 32px;
    line-height: calc(var(--vertical-stride) * 1.75);
    margin: 0;
  }

  /* Remove image aspect ratio hack in the wide version */
  .article-card--wide .article-card__image::before {
    display: none;
  }

  .article-card--wide .article-card__lead-text--short {
    display: none;
  }

  .article-card--wide .article-card__lead-text--long {
    display: block;
  }

  /**
   * @title Wide version with an image
   * @id modules/article-card/wide-with-image
   * @markup article-card/wide-with-image.html
   * @width 12
   */
  .article-card--wide.article-card--image .article-card__image {
    width: calc(100% / 12 * 4);
  }

  .article-card--wide.article-card--image .article-card__content {
    justify-content: flex-start;
    padding-left: var(--horizontal-stride);
    width: calc(100% / 12 * 8);
  }

  .article-card--wide.article-card--image .article-card__body {
    flex: 1;
  }

  .article-card--wide.article-card--image .article-card__lead {
    display: block;
  }
}

/* NO FLEXBOX FALLBACK */

/* Use display: block; instead of flex and force a min height */
.no-flexbox .article-card {
  display: block;
  min-height: var(--card-height-medium-min);
}

/* Position the body near the bottom of the card */
.no-flexbox .article-card__body {
  bottom: 20px;
  left: 20px;
  position: absolute;
  right: 20px;
}

/* If the card has an image we need to position the content and not the body */
.no-flexbox .article-card--image .article-card__content {
  bottom: 20px;
  left: 20px;
  padding: 0;
  position: absolute;
  right: 20px;
}

/* If the card has an image we need to position the content and not the body */
.no-flexbox .article-card--image .article-card__body {
  bottom: 0;
  left: 0;
  position: static;
  right: 0;
}

/* If the card is wide and has an image we need to make room for the image on the left */
.no-flexbox .article-card--wide.article-card--image .article-card__image {
  bottom: 20px;
  left: 20px;
  position: absolute;
  top: 20px;
  width: 30%;
}

.no-flexbox .article-card--wide.article-card--image .article-card__content {
  bottom: 0;
  left: 30%;
  padding-left: 40px;
  position: absolute;
  top: 20px;
}

/* Position the body near the bottom of the card */
.no-flexbox .article-card--wide.article-card--image .article-card__body {
  bottom: 20px;
  left: 20px;
  padding-left: 20px;
  position: absolute;
  right: 20px;
}
