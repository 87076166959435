.image-attribution > button {
  background-color: color(white a(50%));
  border: 0;
  border-radius: 50%;
  line-height: 30px;
  margin: 0 1ex 1ex 0;
  padding: 0;
  text-align: center;
  width: 30px;
}

.image-attribution > p {
  background-color: color(white a(90%));
  display: none;
  font-style: italic;
  margin: 0;
  padding: 1ex 1ex 1ex 2ex;
}

.image-attribution.is-open > button {
  display: none;
}

.image-attribution.is-open > p {
  display: block;
}

@media print {
  .image-attribution > button {
    display: none;
  }

  .image-attribution > p {
    display: block;
    padding: 0;
  }

  .image-attribution > p::before {
    content: "Kreditering: ";
  }
}
