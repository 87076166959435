/**
 * @title Footer
 * @id modules/footer
 * @markup footer/footer.html
 * @width 24
 */
.footer {
  background-color: var(--color-pastel-green);
  font-family: "Mallory MP", sans-serif;
  font-feature-settings: "c2sc";
  font-size: 13px;
  -webkit-font-smoothing: antialiased;
  padding: var(--vertical-stride) 0;
  position: relative;  /* Render this on top of the bottommost borders of the front page table */
}

.footer__header {
  overflow: hidden;
  padding-bottom: calc(var(--vertical-stride) * 2);
}

.footer__masthead,
.footer__logo {
  float: left;
  padding-left: var(--horizontal-stride);
  width: calc((100% / 24) * 6);
}

.footer__title {
  margin-bottom: var(--vertical-stride);
}

.footer__title > svg {
  height: var(--vertical-stride);
  max-width: 100%;
  width: auto;
}

.footer__title > svg > path {
  fill: black;
}

.footer__subtitle {
  margin: 0;
}

.footer__title > span:nth-child(1) {
  font-weight: bold;
}

.footer__logo > svg {
  height: calc(var(--vertical-stride) * 4);
}

.footer__logo > svg > path {
  fill: black;
}

.footer__columns {
  align-items: flex-end;
  display: flex;
  font-size: 13px;
  text-transform: uppercase;
}

.footer__column {
  list-style-type: none;
  margin: 0;
  padding: 0;
  padding-left: var(--horizontal-stride);
  width: calc((100% / 24) * 6);
}

.footer__column a {
  color: inherit;
}

.footer__subheading {
  font-weight: bold;
}

.footer__tel-link,
.footer__homepage-link {
  text-decoration: none;
}

@media (--breakpoint-medium) {
  .footer li {
    font-size: 11px;
    line-height: var(--vertical-stride);
  }

  .footer p {
    font-size: 11px;
    line-height: var(--vertical-stride);
  }

  .footer__columns {
    display: block;
  }

  .footer__column {
    padding: 0 20px 20px;
    width: 100%;
  }

  .footer__masthead {
    padding-bottom: 20px;
    padding-left: 20px;
    width: 80%;
  }
}

@media (--breakpoint-narrow) {
  .footer li {
    font-size: 11px;
    line-height: var(--vertical-stride);
  }

  .footer p {
    font-size: 11px;
    line-height: var(--vertical-stride);
  }

  .footer__columns {
    display: block;
  }

  .footer__column {
    padding: 0 20px 20px;
    width: 100%;
  }

  .footer__masthead {
    padding-bottom: 20px;
    padding-left: 20px;
    width: 80%;
  }
}

@media print {
  .footer {
    display: none;
  }
}
