/**
 * @title Search result
 * @id modules/search-result
 * @markup search-result/search-result.html
 * @width 6
 */
.search-result {
  color: inherit;
  display: flex;
  flex-direction: column;
  text-decoration: none;
}

.search-result:hover {
  text-decoration: underline;
}

.search-result__heading {
  @extend %headline-two;

  margin-bottom: var(--vertical-stride);
}

.search-result__category {
  margin: 0;
  order: -1;
}

.search-result--blue .search-result__category,
.search-result--blue .search-result__date {
  color: var(--color-blue);
}

.search-result--green .search-result__category,
.search-result--green .search-result__date {
  color: var(--color-green);
}

.search-result--orange .search-result__category,
.search-result--orange .search-result__date {
  color: var(--color-orange);
}

.search-result--turquoise .search-result__category,
.search-result--turquoise .search-result__date {
  color: var(--color-turquoise);
}
