/**
 * @title Comment List
 * @id modules/comment-list
 * @markup comment-list/default.html
 * @width 12
 */
.comment-list {
  margin-bottom: var(--vertical-stride);
}

.comment-list > ol {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.comment-list__comment {
  border-bottom: 1px solid silver;
  padding: 20px 0;
}

.comment-list__comment:last-child {
  border-bottom: 0;
}

.comment-list__metadata {
  margin-bottom: 10px;
}

.comment-list__name {
  display: inline;
  font-weight: bold;
  margin: 0;
}

.comment-list__date {
  display: inline;
  margin: 0;
}

.comment-list__text {
  margin: 0;
}

/**
 * @title Standalone
 * @id modules/comment-list/standalone
 * @markup comment-list/standalone.html
 * @width 14
 */
.comment-list__article-source {
  margin: 0;
}

.comment-list__article-link {
  margin-left: 0.5ex;
}

.comment-list__add-comment {
  margin: 0;
  text-align: right;
}

@media print {
  .comment-list {
    display: none;
  }
}
