/**
 * @title Related entries
 * @id modules/related-entries
 * @markup related-entries/related-entries.html
 * @width 10
 */
@custom-media --related-entries-width-18-of-18 (--main-width-18);
@custom-media --related-entries-width-16-of-16 (--main-width-16);
@custom-media --related-entries-width-24-of-24 (--main-width-24);

.related-entries__list {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.related-entries__entry {
  border-bottom: 2px solid var(--color-pastel-green);
  border-left: 2px solid var(--color-pastel-green);
  display: flex;  /* Make sure the article card within is as tall as the cell */
  width: calc(100% / 3);
}

.related-entries__entry:last-child {
  box-shadow: 2px 0 0 0 var(--color-pastel-green);
}

@media (--related-entries-width-24-of-24) {
  .related-entries__list {
    display: block;
  }

  .related-entries__entry {
    border-bottom: 2px solid var(--color-pastel-green);
    border-left: 0;
    width: 100%;
  }

  .related-entries__entry:last-child {
    box-shadow: none;
  }
}

/* NO FLEXBOX FALLBACK */

.no-flexbox .related-entries__list {
  @extend %clearfix;
  display: block;
}

.no-flexbox .related-entries__entry {
  float: left;
}

@media print {
  .related-entries {
    display: none;
  }
}
