/**
 * Introduction paragraph used at the top of articles. Visually very similar to
 * the pull quote module, but without a top margin and no italic.
 *
 * @title Article lead
 * @id modules/article-lead
 * @markup article-lead/article-lead.html
 * @width 24
 */
.article-lead {
  border-bottom: 2px solid var(--color-pastel-green);
  display: block;
  font-size: var(--font-size-tertiary);
  line-height: calc(var(--vertical-stride) * 1.2);
  margin: 0 0 var(--vertical-stride) 0;
  padding: calc(var(--vertical-stride) * 2) calc(var(--horizontal-stride) * 2);
}

.article-lead--turquoise { color: var(--color-turquoise); }
.article-lead--blue { color: var(--color-blue); }
.article-lead--green { color: var(--color-green); }
.article-lead--orange { color: var(--color-orange); }
