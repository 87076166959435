/**
 * @title Comment count
 * @id atoms/comment-count
 * @markup comment-count/comment-count.html
 * @width 4
 */
.comment-count {
  background: var(--color-white);
  border: 1px solid var(--color-black);
  border-radius: 3px;
  color: var(--color-black);
  display: inline-block;
  line-height: 1;
  padding: 7px 10px;
  position: relative;
}

.comment-count::before {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid var(--color-black);
  bottom: -6px;
  content: "";
  left: 4px;
  position: absolute;
}

.comment-count::after {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid var(--color-white);
  bottom: -5px;
  content: "";
  left: 5px;
  position: absolute;
}
