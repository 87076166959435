/**
 * @title Main illustration
 * @id modules/main-illustration
 * @markup main-illustration/main-illustration.html
 * @width 18
 */
.main-illustration {
  border-bottom: 2px solid var(--color-pastel-green);
  position: relative;
}

.main-illustration__image {
  position: relative;
}

/* Aspect ratio */
.main-illustration__image::before {
  content: "";
  display: block;
  padding-top: 60%;
}

.main-illustration__image > img {
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.main-illustration__attribution {
  bottom: 0;
  position: absolute;
  right: 0;
}

@media print {
  .main-illustration__attribution {
    position: static;
  }

  .main-illustration__image {
    /* We need to use !important in order to override the inline style */
    background-image: none !important;  /* stylelint-disable-line */
  }

  .main-illustration__image::before {
    display: none;
  }

  .main-illustration__image > img {
    display: block;
    height: auto;
    margin: 0 auto;
    max-height: 400px;
    max-width: 100%;
    opacity: 1;
    position: static;
    width: auto;
  }
}
