/* Mallory Bold */
@font-face {
  font-family: "Mallory";
  font-style: normal;
  font-weight: 700;
  src: url("/resources/fonts/Mallory-Bold.woff") format("woff");
}

@font-face {
  font-family: "Mallory";
  font-style: italic;
  font-weight: 700;
  src: url("/resources/fonts/Mallory-BoldItalic.woff") format("woff");
}

/* Mallory Medium */
@font-face {
  font-family: "Mallory";
  font-style: normal;
  font-weight: 500;
  src: url("/resources/fonts/Mallory-Medium.woff") format("woff");
}

@font-face {
  font-family: "Mallory";
  font-style: italic;
  font-weight: 500;
  src: url("/resources/fonts/Mallory-MediumItalic.woff") format("woff");
}

/* Mallory Book */
@font-face {
  font-family: "Mallory";
  font-style: normal;
  font-weight: 400;
  src: url("/resources/fonts/Mallory-Book.woff") format("woff");
}

@font-face {
  font-family: "Mallory";
  font-style: italic;
  font-weight: 400;
  src: url("/resources/fonts/Mallory-BookItalic.woff") format("woff");
}

/* Mallory MP Bold */
@font-face {
  font-family: "Mallory MP";
  font-style: normal;
  font-weight: 700;
  src: url("/resources/fonts/MalloryMP-Bold.woff") format("woff");
}

@font-face {
  font-family: "Mallory MP";
  font-style: italic;
  font-weight: 700;
  src: url("/resources/fonts/MalloryMP-BoldItalic.woff") format("woff");
}

/* Mallory MP Medium */
@font-face {
  font-family: "Mallory MP";
  font-style: normal;
  font-weight: 500;
  src: url("/resources/fonts/MalloryMP-Medium.woff") format("woff");
}

@font-face {
  font-family: "Mallory MP";
  font-style: italic;
  font-weight: 500;
  src: url("/resources/fonts/MalloryMP-MediumItalic.woff") format("woff");
}

/* Mallory MP Book */
@font-face {
  font-family: "Mallory MP";
  font-style: normal;
  font-weight: 400;
  src: url("/resources/fonts/MalloryMP-Book.woff") format("woff");
}

@font-face {
  font-family: "Mallory MP";
  font-style: italic;
  font-weight: 400;
  src: url("/resources/fonts/MalloryMP-BookItalic.woff") format("woff");
}
