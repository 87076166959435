@custom-media --cell-width-6-of-18 (--main-width-18);
@custom-media --cell-width-8-of-16 (--main-width-16);
@custom-media --cell-width-24-of-24 (--main-width-24);

@custom-media --wide-cell-width-12-of-18 (--main-width-18);
@custom-media --wide-cell-width-16-of-16 (--main-width-16);
@custom-media --wide-cell-width-24-of-24 (--main-width-24);

.l-table {
  display: flex;
  flex-wrap: wrap;
}

.l-table__cell {
  border-bottom: 2px solid var(--color-pastel-green);
  border-left: 2px solid var(--color-pastel-green);
  display: flex;  /* Make sure the article card within is as tall as the cell */
}

.l-table__cell:last-child {
  box-shadow: 2px 0 0 0 var(--color-pastel-green);
}

@media (--cell-width-6-of-18) {
  .l-table__cell {
    width: calc(100% / 18 * 6);
  }
}

@media (--cell-width-6-of-18) {
  .l-table__cell {
    width: calc(100% / 18 * 6);
  }
}

@media (--cell-width-8-of-16) {
  .l-table__cell {
    width: calc(100% / 16 * 8);
  }
}

@media (--cell-width-24-of-24) {
  .l-table__cell {
    border-left: 0;
    width: 100%;
  }

  .l-table__cell:last-child {
    box-shadow: none;
  }
}

@media (--wide-cell-width-12-of-18) {
  .l-table__cell--wide {
    width: calc(100% / 18 * 12);
  }
}

@media (--wide-cell-width-16-of-16) {
  .l-table__cell--wide {
    width: 100%;
  }
}

@media (--wide-cell-width-24-of-24) {
  .l-table__cell--wide {
    width: 100%;
  }
}

/* NO FLEXBOX FALLBACK */

.no-flexbox .l-table {
  @extend %clearfix;
  display: block;
}

.no-flexbox .l-table__cell {
  float: left;
}
