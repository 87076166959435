%headline-one {
  font-size: 32px; /* testing from 48 to 32px */
  line-height: calc(var(--vertical-stride) * 1.75);
  margin: 0;
}

%headline-two {
  font-size: 20px; /* testing from 27 to 24px */
  line-height: calc(var(--vertical-stride) * 1.2);
  margin: 0;
}

@media (--breakpoint-narrow) {
  %headline-one {
    font-size: 28px; /* testing from 48 to 32px */
    line-height: calc(var(--vertical-stride) * 1.5);
    margin: 0;
  }

  %headline-two {
    font-size: 22px; /* testing from 27 to 24px */
    line-height: calc(var(--vertical-stride) * 1.2);
    margin: 0;
  }
}

