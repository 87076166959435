@custom-media --main-width-18 (--breakpoint-wide);
@custom-media --sidebar-width-6 (--breakpoint-wide);

@custom-media --main-width-16 (--breakpoint-medium);
@custom-media --sidebar-width-8 (--breakpoint-medium);

@custom-media --main-width-24 (--breakpoint-narrow);
@custom-media --sidebar-width-24 (--breakpoint-narrow);

.l-body {
  @extend %clearfix;
}

.l-body__main {
  /* Add a 1px border inside and a 1px border outside the right edge */
  box-shadow: inset -1px 0 0 0 var(--color-pastel-green), 1px 0 0 0 var(--color-pastel-green);
  float: left;
  padding-right: 1px;  /* Make room for the border */
}

.l-body__sidebar {
  /* Add a 1px border inside and a 1px border outside the left edge */
  box-shadow: inset 1px 0 0 0 var(--color-pastel-green), -1px 0 0 0 var(--color-pastel-green);
  float: left;
  padding-left: 1px;  /* Make room for the border */
}

@media (--main-width-18) {
  .l-body__main { width: calc(100% / 24 * 18); }
  .l-body__sidebar { width: calc(100% / 24 * 6); }
}

@media (--main-width-16) {
  .l-body__main { width: calc(100% / 24 * 16); }
  .l-body__sidebar { width: calc(100% / 24 * 8); }
}

@media (--main-width-24) {
  .l-body__main {
    box-shadow: none;
    width: calc(100% / 24 * 24);
  }

  .l-body__sidebar {
    box-shadow: none;
    width: calc(100% / 24 * 24);
  }
}

@media print {
  .l-body__main {
    box-shadow: none;
    width: 100%;
  }

  .l-body__sidebar {
    display: none;
  }
}
