@custom-media --wide-cell-width-6-of-24 (--breakpoint-wide);
@custom-media --wide-cell-width-12-of-24 (--breakpoint-medium);
@custom-media --wide-cell-width-24-of-24 (--breakpoint-narrow);

.l-wide-table {
  display: flex;
  flex-wrap: wrap;
}

.l-wide-table__cell {
  background-color: var(--color-light-green);
  border-bottom: 2px solid var(--color-pastel-green);
  border-left: 2px solid var(--color-pastel-green);
  display: flex;  /* Make sure the article card within is as tall as the cell */
}

.l-wide-table__cell:last-child {
  box-shadow: 2px 0 0 0 var(--color-pastel-green);
}

@media (--wide-cell-width-6-of-24) {
  .l-wide-table__cell {
    width: calc(100% / 24 * 6);
  }
}

@media (--wide-cell-width-12-of-24) {
  .l-wide-table__cell {
    width: calc(100% / 24 * 12);
  }
}

@media (--wide-cell-width-24-of-24) {
  .l-wide-table__cell {
    width: 100%;
  }
}
