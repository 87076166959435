/**
 * @title Result list
 * @id modules/result-list
 * @markup result-list/result-list.html
 * @width 6
 */
.result-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.result-list > li {
  border-bottom: 2px solid var(--color-green);
  margin-bottom: var(--vertical-stride);
  padding: 0 var(--horizontal-stride);
}

.result-list > li:last-child {
  border-bottom: 0;
}
