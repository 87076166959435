/**
 * @title Main menu
 * @id modules/main-menu
 * @markup main-menu/main-menu.html
 * @width 24
 */
.main-menu {
  @extend %clearfix;

  font-family: "Mallory MP", sans-serif;
  font-size: 13px;
}

.main-menu__list {
  float: left;
  list-style-type: none;
  margin: 0;
  padding: 0 calc(100% / 36);
  width: calc(100% / 24 * 6);
}

.main-menu__item {
  font-feature-settings: "c2sc";
  font-weight: bold;
  text-transform: uppercase;
}

.main-menu__item > a {
  color: inherit;
  display: block;
  text-decoration: none;
}

.main-menu__item > a:hover {
  text-decoration: underline;
}

@media (--masthead-narrow) {
  .main-menu {
    padding: 0;
  }

  .main-menu__list {
    border-bottom: 1px solid black;
    float: none;
    margin-bottom: 10px;
    padding: 0 calc(100% / 24) 10px calc(100% / 24);
    width: auto;
  }

  .main-menu__list:last-child {
    margin-bottom: 0;
  }
}

@media (--breakpoint-medium) {
  .main-menu li {
    font-size: 12px;
  }
}
