/**
 * @title Social sharing
 * @id modules/social-sharing
 * @markup social-sharing/social-sharing.html
 * @width 18
 */
.social-sharing {
  list-style-type: none;
  margin: 0 0 var(--vertical-stride) 0;
  padding: 0;
}

.social-sharing > li {
  color: red;
}

.social-sharing__link {
  line-height: calc(var(--vertical-stride) * 2);
  text-decoration: none;
}

.social-sharing__link > svg {
  height: var(--vertical-stride);
  margin-top: calc(var(--vertical-stride) / 2);
  width: var(--vertical-stride);
}

.social-sharing--turquoise .social-sharing__link { color: var(--color-turquoise); }
.social-sharing--blue .social-sharing__link { color: var(--color-blue); }
.social-sharing--green .social-sharing__link { color: var(--color-green); }
.social-sharing--orange .social-sharing__link { color: var(--color-orange); }

.social-sharing--turquoise .social-sharing__link > svg { fill: var(--color-turquoise); }
.social-sharing--blue .social-sharing__link > svg { fill: var(--color-blue); }
.social-sharing--green .social-sharing__link > svg { fill: var(--color-green); }
.social-sharing--orange .social-sharing__link > svg { fill: var(--color-orange); }

.social-sharing__count {
  color: var(--color-black);
}

@media print {
  .social-sharing {
    display: none;
  }
}
