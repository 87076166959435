/**
 * @title Answer
 * @id modules/answer
 * @markup answer/answer.html
 * @width 10
 */
.answer__name {
  float: left;
  font-weight: bold;
  margin: 0;
  padding-right: 0.75ex;
  text-transform: uppercase;
}

.answer__name::after {
  color: black;
  content: "-";
  font-weight: normal;
  padding-left: 0.75ex;
}

.answer--turquoise .answer__name { color: var(--color-turquoise); }
.answer--blue .answer__name { color: var(--color-blue); }
.answer--green .answer__name { color: var(--color-green); }
.answer--orange .answer__name { color: var(--color-orange); }
