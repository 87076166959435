/**
 * @title Category header
 * @id modules/category-header
 * @markup category-header/category-header.html
 * @width 18
 */
.category-header {
  color: white;
  font-family: "Mallory MP", sans-serif;
  font-feature-settings: "c2sc";
  font-size: 13px;
  margin: 0;
  padding: 0 calc(100% / 24);
  text-transform: uppercase;
}

.category-header--turquoise { background-color: var(--color-turquoise); }
.category-header--blue { background-color: var(--color-blue); }
.category-header--green { background-color: var(--color-green); }
.category-header--orange { background-color: var(--color-orange); }
