.stardoc__title {
  color: var(--color-green);
  font-size: 48px;
  margin: 40px 0 80px 0;
  text-align: center;
}

.stardoc__category {
  color: var(--color-turquoise);
  font-size: 48px;
  margin: 40px 0;
}

.stardoc__module {
  margin-bottom: 80px;
}

.stardoc__heading {
  background-color: var(--color-blue);
  color: white;
  font-size: 16px;
  line-height: 30px;
  margin: 0 0 18px 0;
  text-transform: uppercase;
}

.stardoc__heading > a {
  color: inherit;
  display: block;
  padding: 0 10px;
  text-decoration: none;
}

.stardoc__heading > a:hover {
  text-decoration: underline;
}

.stardoc__subheading {
  background-color: color(var(--color-blue) a(10%));
  font-size: 16px;
  line-height: 30px;
  margin: 0 0 18px 0;
}

.stardoc__subheading > a {
  color: inherit;
  display: block;
  padding: 0 10px;
  text-decoration: none;
}

.stardoc__subheading > a:hover {
  text-decoration: underline;
}

.stardoc__example {
  margin: 0 auto;
}

.stardoc__example--intrinsic {
  display: flex;
  justify-content: center;
}

.stardoc__example--24 { max-width: calc((1280px / 24) * 24); }
.stardoc__example--23 { max-width: calc((1280px / 24) * 23); }
.stardoc__example--22 { max-width: calc((1280px / 24) * 22); }
.stardoc__example--21 { max-width: calc((1280px / 24) * 21); }
.stardoc__example--20 { max-width: calc((1280px / 24) * 20); }
.stardoc__example--19 { max-width: calc((1280px / 24) * 19); }
.stardoc__example--18 { max-width: calc((1280px / 24) * 18); }
.stardoc__example--17 { max-width: calc((1280px / 24) * 17); }
.stardoc__example--16 { max-width: calc((1280px / 24) * 16); }
.stardoc__example--15 { max-width: calc((1280px / 24) * 15); }
.stardoc__example--14 { max-width: calc((1280px / 24) * 14); }
.stardoc__example--13 { max-width: calc((1280px / 24) * 13); }
.stardoc__example--12 { max-width: calc((1280px / 24) * 12); }
.stardoc__example--11 { max-width: calc((1280px / 24) * 11); }
.stardoc__example--10 { max-width: calc((1280px / 24) * 10); }
.stardoc__example--9 { max-width: calc((1280px / 24) * 9); }
.stardoc__example--8 { max-width: calc((1280px / 24) * 8); }
.stardoc__example--7 { max-width: calc((1280px / 24) * 7); }
.stardoc__example--6 { max-width: calc((1280px / 24) * 6); }
.stardoc__example--5 { max-width: calc((1280px / 24) * 5); }
.stardoc__example--4 { max-width: calc((1280px / 24) * 4); }
.stardoc__example--3 { max-width: calc((1280px / 24) * 3); }
.stardoc__example--2 { max-width: calc((1280px / 24) * 2); }
.stardoc__example--1 { max-width: calc((1280px / 24) * 1); }

.stardoc__button {
  background-color: transparent;
  border: 0;
  box-shadow: inset 0 0 0 1px var(--color-blue);
  color: var(--color-blue);
  line-height: 30px;
  margin-top: 20px;
  padding: 0 10px;
}

.stardoc__button:hover {
  box-shadow: inset 0 0 0 3px var(--color-blue);
}

.stardoc__markup {
  line-height: 1.6;
}

.stardoc__markup.is-hidden {
  display: none;
}

.stardoc__grid {
  bottom: 0;
  left: 0;
  margin: 0 auto;
  max-width: 1280px;
  opacity: 0.5;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1;
}

.stardoc__grid.is-hidden {
  display: none;
}

.stardoc__grid__column {
  box-shadow: inset 1px 0 0 0 red;
  float: left;
  height: 100vh;
  position: relative;
  width: calc(100% / 12);
}

.stardoc__grid__column:last-child {
  box-shadow: inset 1px 0 0 0 red, inset -1px 0 0 0 red;
}

.stardoc__grid__column::after {
  border-left: 1px solid blue;
  bottom: 0;
  content: "";
  display: block;
  left: 50%;
  position: absolute;
  top: 0;
}

.stardoc__build-tag {
  background: lightblue;
  bottom: 0;
  padding: 2px;
  position: fixed;
  right: 0;
}
