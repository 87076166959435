/**
 * @title News list
 * @id modules/news-list
 * @markup news-list/news-list.html
 * @width 6
 */
.news-list {
  background-color: color(var(--color-light-green) a(40%));
  padding: 0 calc(100% / 10) calc(var(--horizontal-stride) * 0.5);
}

.news-list__heading {
  color: var(--color-blue);  /* Mallory MP Medium 16pt */
  font-family: "Mallory MP", sans-serif;
  font-feature-settings: "c2sc";
  font-size: 13px;
  font-weight: normal;
  margin: 0;
  padding-top: calc(var(--horizontal-stride) * 0.5);
  text-transform: uppercase;
}

.news-list > ol {
  font-family: "Mallory MP", sans-serif;
  font-size: 11px;
  line-height: 16px;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.news-list__item:last-child {
  padding-bottom: 0;
}

.news-list__link {
  color: inherit;
  display: block;
  padding-left: 40px;
  position: relative;
  text-decoration: none;
}

.news-list__link > time {
  color: var(--color-blue);
  left: 0;
  position: absolute;
}

.news-list__link > span {
  text-decoration: underline;
}
