/**
 * @title Citations
 * @id modules/citations
 * @markup citations/citations.html
 * @width 10
 */
.citations {
  font-size: 12px;
  line-height: 16px;
  margin: var(--vertical-stride) 0;
}

.citations > h2 {
  font-size: inherit;
  font-weight: normal;
  margin: 0;
}

.citations > ol {
  counter-reset: citation;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.citations > ol > li {
  hyphens: auto;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.citations > ol > li::before {
  content: counter(citation) ". ";
  counter-increment: citation;
}
