/**
 * @title Blog card
 * @id modules/blog-card
 * @markup blog-card/blog-card.html
 * @width 6
 */
.blog-card {
  background-color: #bfb490;  /* sepia-ish */
  color: white;
  display: flex;
  flex-direction: column;
  height: var(--card-height-medium-fixed);  /* IE10-11 requires a fixed height for flex columns */
  padding: 20px;
  position: relative;
  text-decoration: none;
  width: 100%;
  z-index: 0;  /* Create a new stacking context for the negative z-index used on the image */
}

.blog-card__heading {
  @extend %headline-one;

  align-items: flex-end;
  display: flex;
  flex: 1;
  order: 1;
}

/* https://justmarkup.com/log/2015/07/dealing-with-long-words-in-css/ */
.blog-card__heading > span {
  hyphens: auto;
  overflow-wrap: break-word;
  width: 100%;
  word-wrap: break-word;
}

.blog-card__meta {
  font-feature-settings: "c2sc", "onum";
  font-weight: 100;  /* Mallory MP */
  padding-right: 40px; /* Make some room for comments bubble */
  text-transform: uppercase;
}

.blog-card__date,
.blog-card__category {
  display: inline;
  margin: 0;
}

.blog-card__date::after {
  content: " \2022";
  display: inline;
}

.blog-card__comments {
  opacity: 0.5;
  position: absolute;
  right: 20px;
  top: 20px;
}

.blog-card__image {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

/**
 * Blog card variant used on the blog index page.
 * @title Index
 * @id modules/blog-card/index
 * @markup blog-card/index.html
 * @width 6
 */
.blog-card--index .blog-card__image--sepia,
.blog-card--index .blog-card__image--orange {
  display: none;
}

.blog-card--index:hover .blog-card__image--orange {
  display: block;
}

/* NO FLEXBOX FALLBACK */

/* Use display: block; instead of flex and force a min height */
.no-flexbox .blog-card {
  display: block;
  min-height: var(--card-height-medium-min);
}

/*
  Use an alpha background color to make the background image darker.
  The SVG filter appears to fail in IE9. We just reuse the .no-flexbox selector to target it.
*/
.no-flexbox .blog-card::after {
  background-color: color(black alpha(45%));
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}
