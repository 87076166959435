.l-teaser {
  border-bottom: 2px solid var(--color-pastel-green);
}

.l-teaser--sidebar:last-child {
  border-bottom: 0;
}

@media (--breakpoint-medium-up) {
  /* Special case: Match height of first article teaser on front page, so they
   * are aligned. But still allow the sidebar teaser to grow taller if it needs
   * to do so to accommodate its contents. (And we don't try to align to things
   * on other pages than the front page.)
   */
  .l-teaser--sidebar:first-child {
    min-height: calc(var(--card-height-short-fixed) + 2px);
  }
}
