*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  font-family: "Mallory", sans-serif;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  line-height: calc(var(--vertical-stride) * 1.75);
  margin: 0;
}

ol {
  line-height: calc(var(--vertical-stride) * 0.85);
}

ul,
time,
p {
  line-height: calc(var(--vertical-stride) * 1.1);
  margin: 0 0 var(--vertical-stride) 0;
}

figure {
  margin: 0;
}

svg,
img {
  vertical-align: top;
}

a {
  color: var(--color-turquoise);
}
