/**
 * @title Fact box
 * @id modules/fact-box
 * @markup fact-box/fact-box.html
 * @width 18
 */

/**
 * @title Fact box with a main image
 * @id modules/fact-box/top-image
 * @markup fact-box/main-image.html
 * @width 18
 */

/**
 * @title Fact box with an image
 * @id modules/fact-box/image
 * @markup fact-box/image.html
 * @width 18
 */

/**
 * @title Fact box with a main image and an image
 * @id modules/fact-box/both
 * @markup fact-box/both.html
 * @width 18
 */
.fact-box {
  color: white;
  display: flex;
  flex-direction: column;
  margin: var(--vertical-stride) 0;
}

.fact-box a {
  color: white;
}

.fact-box__content {
  display: flex;
}

.fact-box__heading {
  font-size: 16px;
  font-style: italic;
  font-weight: normal;
  line-height: var(--vertical-stride);
  margin: 0;
}

.fact-box__body {
  flex: 1;
  padding: var(--vertical-stride) var(--horizontal-stride);
}

.fact-box__body ol,
.fact-box__body ul,
.fact-box__body li {
  padding-left: 20px;
}

.fact-box__body ol ol li,
.fact-box__body ol ul li,
.fact-box__body ul ol li,
.fact-box__body ul ul li {
  padding-left: 10px;
}

.fact-box__body ol ol {
  list-style-type: lower-alpha;
}

.fact-box__body > *:last-child {
  margin-bottom: 0;
}

.fact-box__image {
  order: -1;
  position: relative;
  width: calc(100% / 18 * 4);
}

/* Position an interactable image on top of the background image */
.fact-box__image > img {
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.fact-box__main-image {
  order: -1;
  position: relative;
}

/* Aspect ratio */
.fact-box__main-image::before {
  content: "";
  display: block;
  padding-top: 35%;
}

/* Position an interactable image on top of the background image */
.fact-box__main-image > img {
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.fact-box--turquoise { background-color: var(--color-turquoise); }
.fact-box--blue { background-color: var(--color-blue); }
.fact-box--green { background-color: var(--color-green); }
.fact-box--orange { background-color: var(--color-orange); }

/* NO FLEXBOX FALLBACK */

.no-flexbox .fact-box {
  display: block;
}

/* Make room for the image on the left */
.no-flexbox .fact-box--image .fact-box__content {
  padding-left: 150px;
  position: relative;
}

/* Position the image to the left */
.no-flexbox .fact-box__image {
  bottom: 0;
  left: 0;
  position: absolute;
  top: 0;
  width: 150px;
}

@media print {
  .fact-box {
    background-color: white;
    color: black;
  }

  .fact-box__body {
    padding-left: 0;
    padding-right: 0;
  }
}
