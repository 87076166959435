/**
 * @title Pull quote
 * @id modules/pull-quote
 * @markup pull-quote/pull-quote.html
 * @width 24
 */
.pull-quote {
  border-bottom: 2px solid var(--color-pastel-green);
  border-top: 2px solid var(--color-pastel-green);
  display: block;
  font-size: var(--font-size-tertiary);
  font-style: italic;
  line-height: var(--vertical-stride);
  margin: var(--vertical-stride) 0;
  padding: calc(var(--vertical-stride) * 2) calc(var(--horizontal-stride) * 2);
  text-align: center;
}

.pull-quote > *:first-child {
  margin-top: 0;
}

.pull-quote > *:last-child {
  margin-bottom: 0;
}

.pull-quote--turquoise { color: var(--color-turquoise); }
.pull-quote--blue { color: var(--color-blue); }
.pull-quote--green { color: var(--color-green); }
.pull-quote--orange { color: var(--color-orange); }
