/**
 * @title Article meta
 * @id modules/article-meta
 * @markup article-meta/article-meta.html
 * @width 18
 */
@custom-media --article-meta-width-18-of-18 (--main-width-18);
@custom-media --article-meta-width-16-of-16 (--main-width-16);
@custom-media --article-meta-width-24-of-24 (--main-width-24);

.article-meta {
  border-bottom: 2px solid var(--color-pastel-green);
  display: flex;
}

.article-meta__content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: var(--vertical-stride) calc(100% / 18 * 1);
}

.article-meta__headline {
  @extend %headline-one;
  margin-bottom: calc(var(--vertical-stride) / 2);
}

.article-meta__category {
  font-feature-settings: "c2sc";
  margin: 0;
  order: -1;
  text-transform: uppercase;
}

.article-meta__author-title {
  color: inherit;
  margin: 0;
}

.article-meta__author-email {
  color: inherit;
}

.article-meta__author-image {
  min-height: calc(var(--vertical-stride) * 7);
  position: relative;
}

/*
 * The invisible image makes it possible to right click and copy the author
 * image.
 */
.article-meta__author-image > img {
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.article-meta__author-svg {
  display: none;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.article-meta--turquoise .article-meta__category { color: var(--color-turquoise); }
.article-meta--turquoise .article-meta__author-svg--turquoise { display: block; }

.article-meta--blue .article-meta__category { color: var(--color-blue); }
.article-meta--blue .article-meta__author-svg--blue { display: block; }

.article-meta--green .article-meta__category { color: var(--color-green); }
.article-meta--green .article-meta__author-svg--green { display: block; }

.article-meta--orange .article-meta__category { color: var(--color-orange); }
.article-meta--orange .article-meta__author-svg--orange { display: block; }

/**
 * @title With an image
 * @id modules/article-meta/image
 * @markup article-meta/image.html
 * @width 18
 */
.article-meta--image .article-meta__content {
  padding: var(--vertical-stride) calc(100% / 18 * 1);
}

@media (--article-meta-width-18-of-18) {
  .article-meta__author-image {
    width: calc(100% / 18 * 3);
  }

  .article-meta--image .article-meta__content {
    width: calc(100% / 18 * 15);
  }
}

@media (--article-meta-width-16-of-16) {
  .article-meta__author-image {
    width: calc(100% / 16 * 4);
  }

  .article-meta--image .article-meta__content {
    width: calc(100% / 16 * 12);
  }
}

@media (--article-meta-width-24-of-24) {
  .article-meta {
    flex-direction: column;
  }

  .article-meta__author-image {
    margin: 0 auto calc(var(--vertical-stride) / 2) auto;
    max-width: 170px;
    position: relative;
    width: 50%;
  }

  /* Aspect ratio 1:1 */
  .article-meta__author-image::before {
    content: "";
    display: block;
    padding-top: 100%;
  }

  .article-meta--image .article-meta__content {
    width: auto;
  }
}

/* NO FLEXBOX FALLBACK */

.no-flexbox .article-meta {
  display: block;
  position: relative;
}

.no-flexbox .article-meta__headline {
  margin: 0;
}

/* Make room for the image on the right and make the module at least as tall as the image */
.no-flexbox .article-meta--image {
  min-height: 180px;
  padding-right: 150px;
}

/* Position the image to the right */
.no-flexbox .article-meta--image .article-meta__author-image {
  bottom: 0;
  height: 180px;
  position: absolute;
  right: 0;
  width: 150px;
}

@media print {
  .article-meta__author-image {
    display: none;
  }
}
