/**
 * @title Article center card
 * @id modules/article-center-card
 * @markup article-center-card/article-center-card.html
 * @width 18
 */
@custom-media --article-center-card-width-16-of-16 (--main-width-16);
@custom-media --article-center-card-width-18-of-18 (--main-width-18);
@custom-media --article-center-card-width-24-of-24 (--main-width-24);

.article-center-card {
  color: white;
  display: flex;
  flex-direction: column;
  height: var(--card-height-short-fixed);  /* IE10-11 requires a fixed height for flex columns */
  justify-content: center;
  padding: var(--vertical-stride) calc(100% / 18 * 3);
  position: relative;
  text-align: center;
  text-decoration: none;
  z-index: 0;  /* Create a new stacking context for the negative z-index used on the image */
}

/* Override the IE10-11 workaround */
@supports (display: flex) {
  .article-center-card {
    height: auto;
    min-height: var(--card-height-short-min);
  }
}

.article-center-card::after {
  background-color: color(black alpha(30%));
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.article-center-card__heading {
  font-size: 38px;
  line-height: 42px;
  margin: 0;
}

.article-center-card__category {
  margin: 0;
  order: -1;
  text-transform: uppercase;
}

.article-center-card__image {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

/* Position an interactable image on top of the background image */
.article-center-card__image > img {
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.article-center-card__comments {
  opacity: 0.5;
  position: absolute;
  right: 10px;
  top: 10px;
}

.article-center-card--turquoise::after {
  background-color: color(var(--color-turquoise) alpha(20%));
  background-image: linear-gradient(transparent 20%, var(--color-turquoise) 100%);
}

.article-center-card--green::after {
  background-color: color(var(--color-green) alpha(20%));
  background-image: linear-gradient(transparent 20%, var(--color-green) 100%);
}

.article-center-card--blue::after {
  background-color: color(var(--color-blue) alpha(20%));
  background-image: linear-gradient(transparent 20%, var(--color-blue) 100%);
}

.article-center-card--orange::after {
  background-color: color(var(--color-orange) alpha(20%));
  background-image: linear-gradient(transparent 20%, var(--color-orange) 100%);
}

/* Use display: block; when flexbox is not supported and fake vertical centering */
.no-flexbox .article-center-card {
  display: block;
  padding-top: calc(var(--vertical-stride) * 2);
}

/* Use just background color if CSS gradients are not supported */
.no-cssgradients .article-center-card::after { background-color: color(black alpha(45%)); }
.no-cssgradients .article-center-card--turquoise::after { background-color: color(var(--color-turquoise) alpha(45%)); }
.no-cssgradients .article-center-card--green::after { background-color: color(var(--color-green) alpha(45%)); }
.no-cssgradients .article-center-card--blue::after { background-color: color(var(--color-blue) alpha(45%)); }
.no-cssgradients .article-center-card--orange::after { background-color: color(var(--color-orange) alpha(45%)); }

@media (--article-center-card-width-24-of-24) {
  .article-center-card__heading {
    /*
      @extend %headline-two;

      We can't use extend within a media, so we need to hardcode the same values
      as the placeholder has, sadly.
    */
    font-size: 22px;
    line-height: calc(var(--vertical-stride) * 1.2);
    margin: 0;
  }
}
