/**
 * @title Article video
 * @id modules/article-video
 * @markup article-video/article-video.html
 * @width 12
 */
.article-video {
  margin: var(--vertical-stride) 0;
  padding-top: calc(100% / 16 * 9);
  position: relative;
}

.article-video > iframe {
  border: none;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
