/**
 * @title Article subheading
 * @id modules/article-subheading
 * @markup article-subheading/article-subheading.html
 * @width 24
 */
.article-subheading {
  @extend %headline-two;

  margin: var(--vertical-stride) 0;
  text-align: center;
}

.article-subheading--turquoise { color: var(--color-turquoise); }
.article-subheading--blue { color: var(--color-blue); }
.article-subheading--green { color: var(--color-green); }
.article-subheading--orange { color: var(--color-orange); }
