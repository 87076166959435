/**
 * Icon and placeholder text not finished.
 *
 * @title Search field
 * @id modules/search-field
 * @markup search-field/search-field.html
 * @width 6
 */
.search-field {
  border-bottom: 1px solid black;
  line-height: var(--vertical-stride);
  padding-left: 30px;
  position: relative;
}

.search-field__input {
  background-color: transparent;
  border: 0;
  line-height: 23px;
  padding: 0 5px 1px 10px;
  text-transform: uppercase;
  width: 100%;
}

.search-field__input::placeholder {
  color: color(gray(70%));
}

.search-field__button {
  background-color: transparent;
  border: 0;
  bottom: 0;
  height: 24px;
  left: 0;
  padding: 0;
  position: absolute;
  top: 0;
  width: 24px;
}

.search-field__button > svg {
  height: 100%;
  left: 0;
  padding: 2px 4px 6px 4px;
  position: absolute;
  top: 0;
  width: 100%;
}
