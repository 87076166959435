/**
 * @title Main video
 * @id modules/main-video
 * @markup main-video/main-video.html
 * @width 18
 */
.main-video {
  padding-top: calc(100% / 16 * 9);
  position: relative;
}

.main-video > iframe {
  border: none;
  border-bottom: 2px solid var(--color-pastel-green);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
